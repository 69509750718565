import React, { useState } from 'react';
import { StoryblokAsset } from '../../../interfaces/Storyblok/StoryblokAsset.interface';
import Image from '../../atoms/Image/Image.atom';
import BackgroundIcons from '../BackgroundIcons/BackgroundIcons.molecule';
import { BackgroundPositionTypes } from '../../../interfaces/enums/BackgroundPositionTypes.enum';
import { BreakpointTypes } from '../../../interfaces/enums/BreakpointTypes.enum';
import { Event, Venue } from '../../../contexts/VenuesContext';
import moment from 'moment';
import Calendar from '../../../assets/svgs/Calendar.svg';
import { BorderIconsProps } from '../../../interfaces/Storyblok/BlokWithBorderIcons.interface';
import BorderIconsFactory from '../../utils/BorderIconsFactory/BorderIconsFactory.util';
import { BorderIconType } from '../../utils/BorderIconsFactory/BorderIcon.util';
import Time from '../../../assets/svgs/Time.svg';
import classNames from 'classnames';

interface HolidayProgramScheduleProps extends BorderIconsProps {
  venue: Venue;
  title: string;
  sub_heading: string;
  full_fee_label: string;
  discounted_fee_label: string;
  icon: StoryblokAsset;
  club_name: string;
  card_border_icon_top_left: StoryblokAsset;
}

interface HolidayProgramScheduleCardProps extends BorderIconsProps {
  event: Event;
  full_fee_label: string;
  discounted_fee_label: string;
}

export const HolidayProgramScheduleCard: React.FC<HolidayProgramScheduleCardProps> = ({
  event: {
    image,
    type,
    eventName,
    date,
    additionalCost,
    discountedCost,
    description,
    startTime,
    endTime,
    eventCategories,
    experienceType,
  },
  event,
  full_fee_label,
  discounted_fee_label,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
}) => {
  return (
    <li className="bg-White rounded-[16px] overflow-hidden col-start-1 col-span-12 lg:col-start-2 lg:col-span-10 xl:col-span-6">
      <BorderIconsFactory
        type={BorderIconType.Card}
        border_icon_top_left={border_icon_top_left}
        border_icon_top_right={border_icon_top_right}
        border_icon_bottom_left={border_icon_bottom_left}
        border_icon_bottom_right={border_icon_bottom_right}
      >
        <div className="py-[24px] sm:py-[40px] px-[24px] list-none">
          <div className="sm:flex sm:space-x-12">
            <div className="w-full md:max-w-[12rem] pb-6 sm:pb-0">
              <img
                src={image}
                alt=""
                className="relative z-20 rounded-[16px] overflow-hidden aspect-square w-full block"
              />
            </div>
            <div className="w-full">
              <div className="">
                <div className="flex gap-x-4 justify-between w-full">
                  <div>
                    {type && (
                      <p className="px-2 py-1 text-sm bg-Secondary-100 rounded-[4px] inline-block mb-3 uppercase text-Primary-900 font-semibold">
                        {type}
                      </p>
                    )}
                    <p className="text-Primary-900 text-2xl font-semibold pb-2">{eventName}</p>
                  </div>
                  {experienceType?.icon && (
                    <div className="rounded-full min-w-[48px] min-h-[48px] w-[48px] h-[48px] sm:-mt-4">
                      <Image image={{ filename: experienceType?.icon }} objectFit="cover" />
                    </div>
                  )}
                </div>

                {/* grid */}
                <div className="space-y-3 md:space-y-0 md:grid grid-cols-2 gap-x-2 grid-flow-dense text-sm py-3">
                  {/* date */}
                  <div className="flex space-x-2 col-start-1">
                    <Calendar className="text-Secondary-300" />
                    <p
                      className={classNames('text-Body font-semibold uppercase', {
                        'pb-2': startTime,
                        'md:pb-4': !startTime,
                      })}
                    >
                      {moment(date).format('ddd, DD MMM YYYY')}
                    </p>
                  </div>

                  {startTime && (
                    <div className="flex space-x-2 col-start-1">
                      <Time className="text-Secondary-300" />
                      <p className="text-Body sm:pb-4 flex space-x-2">
                        <span>
                          Starts: <b>{startTime}</b>
                        </span>
                        {endTime && (
                          <span>
                            Ends: <b>{endTime}</b>
                          </span>
                        )}
                      </p>
                    </div>
                  )}

                  {/* Exp fee */}
                  <div className="col-start-2">
                    <p
                      className={classNames({
                        hidden: Number(additionalCost) === 0 && Number(discountedCost) === 0,
                      })}
                    >
                      {full_fee_label || 'Experience Fee'}:{' '}
                      <span className="font-bold">
                        {process.env.GATSBY_COUNTRY_CURRENCY_CODE}
                        {parseFloat(`${additionalCost}`).toFixed(2)}
                      </span>
                    </p>
                  </div>

                  <div className="col-start-2">
                    {!!discountedCost && (
                      <p
                        className={classNames({
                          hidden: Number(additionalCost) === 0 && Number(discountedCost) === 0,
                        })}
                      >
                        {discounted_fee_label || 'Fee after max. CCS'}:{' '}
                        <span className="font-bold">
                          {process.env.GATSBY_COUNTRY_CURRENCY_CODE}
                          {parseFloat(`${discountedCost}`).toFixed(2)}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {!!eventCategories?.length && (
                <div className="grid grid-cols-1 md:grid-cols-2 2xl:grid-cols-1 3xl:grid-cols-2 gap-4 grid-flow-dense text-sm py-4 mt-3 border-b border-t border-dashed border-Body-Disabled">
                  {eventCategories.map(category => (
                    <div className="flex space-x-2 items-center">
                      <div className="rounded-full min-w-[40px] min-h-[40px] w-[40px] h-[40px]">
                        <Image image={{ filename: category.icon }} objectFit="cover" />
                      </div>
                      <p>{category.name}</p>
                    </div>
                  ))}
                </div>
              )}
              <p className="pt-4">{description}</p>
            </div>
          </div>
        </div>
      </BorderIconsFactory>
    </li>
  );
};
const HolidayProgramSchedule: React.FC<HolidayProgramScheduleProps> = ({
  venue,
  title,
  sub_heading,
  full_fee_label,
  discounted_fee_label,
  icon,
  club_name,
  border_icon_top_left,
  border_icon_top_right,
  border_icon_bottom_left,
  border_icon_bottom_right,
  card_border_icon_top_left,
}) => {
  const service = venue?.services?.find(service => club_name === service.name);

  const groups = service?.events?.map(event => event.group);
  const navigationListItems = groups
    ?.filter((element, idx) => {
      return groups.indexOf(element) === idx;
    })
    .sort();
  const [activeNavItem, setActiveNavItem] = useState<string | null>(
    navigationListItems ? navigationListItems[0] : null,
  );

  // const [activeExperienceType, setActiveExperienceType] = useState<string | null>(null);

  const subHeading = sub_heading || (service?.startDate && service?.endDate);

  const orderByDate = (services: Event[]) => {
    function dateToNum(d) {
      d = d.split('-');
      return Number(d[0] + d[1] + d[2]);
    }

    return services.sort(function (a, b) {
      return dateToNum(a.date) - dateToNum(b.date);
    });
  };

  const events = service?.events && orderByDate(service?.events);

  const experienceTypes = service?.events
    ?.map(event => event?.experienceType)
    ?.filter(item => item && item.name && item.icon) // Exclude invalid items
    ?.filter((item, index, self) => index === self.findIndex(t => t.name === item.name && t.icon === item.icon));

  return (
    <>
      {venue && service?.events && (
        <section className="bg-Primary-900" id="holiday-program-schedule">
          <BorderIconsFactory
            type={BorderIconType.Blok}
            border_icon_top_left={border_icon_top_left}
            border_icon_top_right={border_icon_top_right}
            border_icon_bottom_left={border_icon_bottom_left}
            border_icon_bottom_right={border_icon_bottom_right}
          >
            <div className="container mx-auto py-12 md:py-16 lg:py-20">
              <BackgroundIcons
                type={BackgroundPositionTypes.TopLeftToBottomRight_Outside}
                visibleOn={BreakpointTypes.XL}
                svg={<Image image={icon}></Image>}
                mirrorXLeft
                mirrorYLeft
              >
                {title && <p className="text-4xl text-White font-bold text-center pb-6">{title}</p>}
                {subHeading && (
                  <p className="text-xl text-White font-semibold text-center pb-16">
                    {sub_heading || `${service?.startDate} to ${service?.endDate}`}
                  </p>
                )}
              </BackgroundIcons>
              <div className="justify-center border-b border-White grid grid-flow-row sm:flex">
                <ul className="flex space-x-8">
                  {navigationListItems?.map((item, idx) => (
                    <li
                      key={item}
                      className={`cursor-pointer text-White font-bold uppercase py-4 px-2 ${
                        activeNavItem === item ? 'text-SP3 border-b border-SP3' : ''
                      }`}
                      onClick={() => setActiveNavItem(item)}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>

              <div className="justify-start grid grid-flow-row sm:flex pt-10 sm:pt-12 gap-6 flex-wrap">
                {experienceTypes?.map((type, idx) => (
                  <div className="flex justify-center items-center gap-x-3">
                    <div className="rounded-full min-w-[48px] min-h-[48px] w-[48px] h-[48px]">
                      <Image image={{ filename: type.icon }} objectFit="cover" />
                    </div>
                    <p className="text-White font-bold text-sm">{type.name}</p>
                  </div>
                ))}
              </div>

              {service?.events && (
                <ul className="grid grid-cols-12 gap-x-6 gap-y-10 pt-10 pb-20">
                  {events?.map((event, idx) => {
                    if (event.group === activeNavItem) {
                      return (
                        <HolidayProgramScheduleCard
                          key={`HolidayProgramScheduleCard-${idx}`}
                          event={event}
                          border_icon_top_left={card_border_icon_top_left}
                          full_fee_label={full_fee_label}
                          discounted_fee_label={discounted_fee_label}
                        />
                      );
                    }
                  })}
                </ul>
              )}
            </div>
          </BorderIconsFactory>
        </section>
      )}
    </>
  );
};

export default HolidayProgramSchedule;
